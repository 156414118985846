class ShowMore {
	constructor(element) {
		this.content = element.querySelector("[data-show-more-content]");
		this.button = element.querySelector("[data-show-more-btn]");
		this.textShowMore = JSON.parse(this.button.dataset.showMoreBtn).showMore;
		this.textShowLess = JSON.parse(this.button.dataset.showMoreBtn).showLess;
		this.maxHeight = this.content.dataset.maxHeight || 288;
		this.targetHeight = this.content.dataset.targetElementHeight
			? document.querySelector(this.content.dataset.targetElementHeight)?.clientHeight
			: false;
		this.isVisible = false;
		if (this.targetHeight) {
			this.maxHeight = this.targetHeight;
		}
		if (this.content.clientHeight < this.maxHeight) {
			this.button.remove();
		} else {
			this.init();
		}
		// this.init();
	}

	init() {
		if (this.content && this.button) {
			this.toggleContent();
		}
	}

	toggleContent() {
		// if (this.content.clientHeight > this.maxHeight) {
		this.hideContent();
		this.button.addEventListener("click", (e) => {
			e.preventDefault();
			if (this.isVisible) {
				this.hideContent();
			} else {
				this.showContent();
			}
		});
		// }
	}

	showContent() {
		this.content.style.height = "auto";
		this.button.textContent = this.textShowLess;
		this.button.classList.add("!bg-none");
		this.button.classList.add("!p-0");
		this.button.classList.add("!h-auto");
		this.isVisible = true;
	}

	hideContent() {
		this.content.style.height = this.maxHeight + "px";
		this.button.textContent = this.textShowMore;
		this.button.classList.remove("!bg-none");
		this.button.classList.remove("!p-0");
		this.button.classList.remove("!h-auto");
		this.isVisible = false;
	}
}
export class ShowMoreLC {
	constructor(element) {
		this.content = element.querySelector("[data-show-more-content]");
		this.button = element.querySelector("[data-show-more-btn]");
		this.textShowMore = JSON.parse(this.button.dataset.showMoreBtn).showMore;
		this.textShowLess = JSON.parse(this.button.dataset.showMoreBtn).showLess;
		this.isVisible = false;
		this.isVisible = false;
		this.lineHeight = parseFloat(window.getComputedStyle(this.content).getPropertyValue('line-height'));
		
		this.heightContent = this.content.clientHeight;
		if (this.heightContent > this.lineHeight * 5) {
			this.init();
			element.showMore = this
		} else {
			this.button.remove();
		}
	}

	init() {
		this.content.classList.add("line-clamp-4");
		if (this.content && this.button) {
			this.toggleContent();
		}
	}

	toggleContent() {
		// if (this.content.clientHeight > this.maxHeight) {
		this.hideContent();
		this.button.addEventListener("click", (e) => {
			e.preventDefault();
			if (this.isVisible) {
				this.hideContent();
			} else {
				this.showContent();
			}
		});
		// }
	}

	showContent() {
		this.button.textContent = this.textShowLess;
		this.content.classList.remove("line-clamp-4");
		this.isVisible = true;
	}

	hideContent() {
		this.content.classList.add("line-clamp-4")
		this.button.textContent = this.textShowMore;
		this.isVisible = false;
	}
}
export default ShowMore;

