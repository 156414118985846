class Burger {
    constructor() {
        this.btnToggleNav = document.getElementById("burgerButton");
        this.navigationBlock = document.getElementById("burgerNavigation");
        this.overlayBlock = document.getElementById("burgerOverlay");
        this.setupListeners();
    }
    
    
    setupListeners() {
        this.btnToggleNav?.addEventListener("click", this.toggleBurger.bind(this));
        this.overlayBlock?.addEventListener("click", this.toggleBurger.bind(this));
        // this.navigationBlock?.querySelectorAll("li").forEach((el) => {
        //     el.addEventListener("click", this.toggleBurger.bind(this));
        // });
    }
  
    toggleBurger() {
        document.getElementById('body').classList.toggle('overflow-hidden')
        this.btnToggleNav.querySelectorAll('span').forEach((node) => {
            node.classList.toggle("opacity-0");
            node.classList.toggle("-rotate-90");
        });
        this.navigationBlock.classList.toggle("hidden");
        this.overlayBlock.classList.toggle("hidden");
    }
}

export default Burger;